import React from "react"
import Tilt from 'react-tilt'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import BusinessCard from '../images/biz-card-4';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', padding: '32px', background: 'linear-gradient(-20deg, #EC700C 52.5%, transparent 50%)' }}>
      <Tilt options={{ max : 25 }} style={{ width: '100%', maxWidth: '768px', margin: 'auto 0'}}>
        <BusinessCard style={{ width: '100%', borderRadius: '8px', boxShadow: '0 0 16px 8px rgba(0,0,0,0.15)', background: 'white' }} />
      </Tilt>
    </div>
  </Layout>
)

export default IndexPage
